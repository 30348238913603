.technologyThornWeUseButtonActive {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: White;
  background-color: #195ca5 !important;
  padding: 10px;
  border-radius: 5px;
}

.technologyThornWeUseButtonInactive {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #195ca5;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #195ca5;
}
