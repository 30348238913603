.nav-title {
  box-shadow: 2px 2px 16px 0px #0000000d;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
}
.follow-section {
  background: url(../../assets/Components/Services/follow.png);
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.follow-section p {
  color: white;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
}

.details-header {
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
}

.service-description p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #858e96;
}

.service-description span {
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
}

.service-paragraph-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 25.2px;
  color: #195ca5;
}

.black-point {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
}

.gray-point {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #858e96;
}
