.trustedByGlobalBrandsTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  margin-bottom: 30px;
}

.trustedByGlobalBrandsCardContainer {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
}

.trustedByGlobalBrandsCardContainer:hover {
  box-shadow: 5px 4px 13px 0px #0000001a;
}

.trustedByGlobalBrandsCardTitle {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #858e96;
  margin-top: 30px;
}
