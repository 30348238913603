.slider-button {
  background: #fafafa;
  width: 38px !important;
  height: 38px !important;
  border: 1px solid #d4d4d4;
  border-radius: 50% !important;
  z-index: 2;
  color: #858e96;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  box-shadow: 0 2px 8px 2px rgba(20, 23, 28, 0.15) !important;
  background-color: #fff;
  color: #195ca5;
}

/* react slick default section */
.slick-next:before,
.slick-prev:before {
  content: "";
}

.slider-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.slick-disabled {
  display: none !important;
}

.slick-next {
  right: -2px;
}

.slick-prev {
  left: -2px;
}
