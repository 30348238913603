.reuseableFooterBannerContainer {
  background-color: #2792d0;
  display: flex;
}

.reuseableFooterBannerContentBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .reuseableFooterBannerContentBox {
    display: block;
    padding: 20px 0;
  }
}

.reuseableFooterBannercontentTitle {
  font-family: Outfit;
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  .reuseableFooterBannercontentTitle {
    font-size: 26px;
    line-height: 35px;
  }
}

.reuseableFooterBannercontent {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fafafa;
  margin-top: 5px;
}

.reuseableFooterBannerContentBox::before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background-image: url(../../../assets/Components/Home/MapSection/leftSideBannerImage.png);
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  content: '';
}

.reuseableFooterBannerButtonText {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #2792d0;
  background-color: #ffffff !important;
  padding: 8px 20px;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.reuseableFooterBannerButtonText:hover {
  background-color: black !important;
  color: #ffffff !important;
}

@media screen and (max-width: 768px) {
  .reuseableFooterBannerButtonText {
    font-size: 14px;
    line-height: 20px;
    padding: 7px 16px;
  }
}

/* @media screen and (max-width: 576px) {
  .reuseableFooterBannerButtonText {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 14px;
  }
} */
