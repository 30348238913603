.technologyThornWeUseCardTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.technologyThornWeUseCardContainer {
  border: 1px solid #d4d4d4;
  padding: 20px;
  background-color: #ffffff;
}

.technologyThornWeUseCardContainer:hover {
  border: none;
  box-shadow: -4px 10px 25px 0px #b4761a40;
}
