.bannerTitle {
  font-family: Outfit;
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.bannerButtonText {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #2792d0;
  background-color: #ffffff !important;
  padding: 8px 20px;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.mapBackground {
  background: url(../../../assets/Components/Home/MapSection/mapImage.webp);
  height: 587px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mapCardTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.mapCardItem {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #858e96;
}

.mapCardItemMargin {
  margin-top: 10px;
}
