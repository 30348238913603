.career-hero {
  background: url(../../assets/Components/Career/career.webp);
  height: 290px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.career-card {
  background: rgba(25, 92, 165, 0.1);
}

.career-card-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
}

.career-card-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #858e96;
}

.career-footer {
  background: url(../../assets/Components/Career/join.webp);
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.career-footer-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
