.workflowBackgroundImage {
  background: url(../../../assets/Components/Home/Workflow/workflowBackgroundImage.webp);
  min-height: 542px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.workflowText {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #195ca5;
}

.workflowTitle {
  font-family: Outfit;
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
}

.workflowContent {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #858e96;
}
