@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
html {
  scroll-behavior: smooth;
  height: 100vh;
  overflow-x: hidden;
}

body {
  font-family: "Outfit", sans-serif !important;
}

::-webkit-scrollbar {
  width: 0.625rem;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #2792d0;
}

.slick-prev,
.slick-next {
  display: none !important;
}

/* .slick-slider {
  margin-left: 1rem;
  margin-right: 1rem;
} */
