/* .logo {
  width: 140px;
  height: 48px;
} */

.navberLinkContent {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 10px;
  cursor: pointer;
}

.navberLinkContent:hover {
  color: #2792d0;
}

.navberGetSolutionButton {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
  background-color: #2792d0;
  padding: 12px 32px;
}
