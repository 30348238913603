.servicesWeAreOfferingBackgroudImage {
  background: url(../../../assets/Components/Home/ServicesWeAreOffering/ServicesWeAreOfferingBackgroudImage.png);
  min-height: 692px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.servicesWeAreOfferingText {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #d4d4d4;
}

.servicesWeAreOfferingTitle {
  font-family: Outfit;
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  color: #ffffff;
}
