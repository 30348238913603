.devopsSolutionCardContainer {
  box-shadow: 2px 2px 15px 0px #00000012;
  padding: 39px 20px;
  background-color: white;
}

.devopsSolutionCardImageContainer {
  background-color: #195ca5;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.devopsSolutionCardImage {
  height: 40px;
}

.devopsSolutionCardListMargin {
  margin-top: 10px;
}
