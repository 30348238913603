.techManagementBackGround {
  background: url(../../../assets/Components/Home/TechManagement/TechManagementBackGround.webp);
  min-height: 646px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.techManagementLeftSubTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  color: #ffffff;
}

.techManagementRightSubTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: #ffffff;
}

.techManagementListItem {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fafafa;
}

.techManagementMiniTitle {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #2792d0;
}

.techManagementLeftContentMargin {
  margin-top: 20px;
}

/*  */
/* .techManagementImageContainer {
  width: 41vw;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 1900px) {
  .techManagementImageContainer {
    max-width: 600px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% + 191px);
  }
} */

.techManagementImageContainer {
  max-width: 800px !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% + 191px);
  //right: 0px;
}

@media screen and (min-width: 1800px) {
  .device-management {
    padding: 85px 0px !important;
  }
}
