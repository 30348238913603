.excellenceCardShadow {
  box-shadow: 0px 0px 15px 0px #00000012;
}

.excellenceCardNumberText {
  font-family: Outfit;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
}

.excellenceCardTitle {
  font-family: Outfit;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
