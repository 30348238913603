.slick-slide > div {
  margin: 0 20px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .slick-slide > div {
    margin: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .slick-slide > div {
    margin: 0 10px;
  }
}

.slick-dots {
  display: none !important;
}
