.eCitySolutionCardTitle {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.eCitySolutionCardImage {
  height: 70px;
}

@media screen and (max-width: 991px) {
  .eCitySolutionCardImage {
    height: 50px;
  }
  .eCitySolutionCardTitle {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .eCitySolutionCardImage {
    height: 40px;
  }
  .eCitySolutionCardTitle {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

.eCitySolutionCardShadow {
  box-shadow: 2px 2px 20px 0px #0000001a;
}
