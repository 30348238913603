.eCityDetailsCardsTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

/* Card */

.eCityDetailsCardTitle {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
}
