.footer-section {
  background: url(../../assets/Footer/bg.webp);
  min-height: 520px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #858e96;
}

.footer-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  color: #ffffff;
}
