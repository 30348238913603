.servicesWeAreOfferingCardContainer {
  width: 271px;
  min-height: 192px;
}
@media screen and (max-width: 350px) {
  .servicesWeAreOfferingCardContainer {
    width: 220px;
    min-height: 182px;
  }
}

.servicesWeAreOfferingCardTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.servicesWeAreOfferingCardContent {
  font-family: Outfit;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #d4d4d4;
  text-align: center;
}

.servicesWeAreOfferingCardContentMargin {
  margin-top: 15px;
}
