/* hero */

.hero-background-image {
  background: url(../../../assets/Components/Home/Hero/backgroundImage.webp);
  height: 734px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroTitle1 {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.heroTitle2 {
  font-family: Outfit;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.heroContent {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.DiscoverMoreButton {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
  background-color: #2792d0 !important;
  padding: 12px 32px;
}

@media screen and (max-width: 768px) {
  .heroTitle1 {
    font-size: 12px;
    line-height: 14px;
  }

  .heroTitle2 {
    font-size: 40px;
    line-height: 43px;
  }

  .heroContent {
    font-size: 16px;
    line-height: 18px;
  }

  .DiscoverMoreButton {
    font-size: 12px;
    line-height: 16px;
    padding: 12px 32px;
  }
}

@media screen and (max-width: 576px) {
  .heroTitle1 {
    font-size: 11px;
    line-height: 13x;
  }

  .heroTitle2 {
    font-size: 28px;
    line-height: 30px;
  }

  .heroContent {
    font-size: 14px;
    line-height: 16px;
  }

  .DiscoverMoreButton {
    font-size: 11px;
    line-height: 13px;
    padding: 8px 20px;
  }
}

/* hero footer section */
