.whyChooseSmartmatrixSectionMargin {
  margin-top: 90px;
  margin-bottom: 90px;
}

.whyChooseSmartmatrixMargin {
  margin-top: 20px;
}

.whyChooseSmartmatrixListItemNumber {
  font-family: Outfit;
  font-size: 45px;
  font-weight: 600;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: left;
  color: #195ca5;
}

.whyChooseSmartmatrixListTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.whyChooseSmartmatrixListContent {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #858e96;
}

.whyChooseSmartmatrixListReadMore {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #195ca5;
}
