/* .aboutOurCompany {
  background: url(../../assets/Components/AboutOurCompany/aboutOurCompanyLeftBackground.png);
  height: 907px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
} */

.satisfiedClientsContainer {
  box-shadow: 3px 3px 10px 0px #00000012;
  background-color: white;
}

.satisfiedClientsTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #195ca5;
}

.satisfiedClientsContent {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #858e96;
}

/* About Our Company Section */

.aboutOurCompanyRightSectionMargin {
  margin-top: 20px;
}
