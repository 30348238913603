/* hero */

.about-hero {
  background: url(../../assets/Components/About/about-hero.webp);
  height: 290px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #195ca5;
}

.heroTitle {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  color: #ffffff;
}

.main-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
}

.para-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #858e96;
}

.service-card {
  box-shadow: 2px 2px 15px 0px #00000026;
}

.about-page-solution {
  background: url(../../assets/Components/About/about-bg.webp);
  max-height: 620px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-page-footer {
  background: url(../../assets/Components/About/footer-bg.png);
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-page-footer-para {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  color: #195ca5;
}

.about-page-footer-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  padding-left: 14px;
  color: #858e96;
}

@media screen and (max-width: 768px) {
  .heroTitle2 {
    font-size: 40px;
    line-height: 43px;
  }
}

@media screen and (max-width: 576px) {
  .heroTitle2 {
    font-size: 28px;
    line-height: 30px;
  }
}
