.buttonPrimary {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
  background-color: #2792d0 !important;
  padding: 12px 32px;
}

@media screen and (max-width: 991px) {
  .buttonPrimary {
    font-size: 14px;
    line-height: 16px;
    padding: 12px 32px;
  }
}

@media screen and (max-width: 576px) {
  .buttonPrimary {
    font-size: 12px;
    line-height: 13px;
    padding: 10px 26px;
  }
}

/* Used In */
.sectionTitle {
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
}

.sectionContent {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #858e96;
}

@media screen and (max-width: 991px) {
  .sectionTitle {
    font-size: 30px;
    line-height: 35px;
  }

  .sectionContent {
    font-size: 15px;
    line-height: 18px;
  }
}
@media screen and (max-width: 575px) {
  .sectionTitle {
    font-size: 26px;
    line-height: 30px;
  }

  .sectionContent {
    font-size: 14px;
    line-height: 16px;
  }
}

.sectionMarginBetweenSectionTitleAndSectionContent {
  margin-top: 20px;
}

/* margin top 90px Section */
.sectionContentMargin1 {
  margin-top: 90px;
}

.sectionListTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.sectionListListContent {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #858e96;
}
