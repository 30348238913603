.serviceDropdownContainer {
  background-color: #ffffff !important;
  box-shadow: 3px 4px 10px 0px #00000040 !important;
  z-index: 10 !important;
  width: 636px !important;
  padding: 30px !important;
}

.serviceDropdownTitle {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.serviceDropdownContent {
  font-family: Outfit;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #858e96;
  margin-top: 5px;
}

.serviceDropdownLink {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-top: 10px;
}

.serviceDropdownLink:hover {
  color: #2792d0;
  border-bottom: 1px solid #2792d0;
}
