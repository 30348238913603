.recentlyCompletedWorkCardTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #ffffff;
}

.recentlyCompletedWorkCardCetagories {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #fafafa;
}

.recentlyCompletedWorkCardListItemContent {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #fafafa;
}

/* @media (min-width: 350px) and (max-width: 576px) {
  .recentlyCompletedWorkCardTitle {
    font-size: 16px;
    line-height: 18px;
  }
} */

@media (max-width: 576px) {
  .recentlyCompletedWorkCardTitle {
    font-size: 16px;
    line-height: 18px;
  }

  .recentlyCompletedWorkCardCetagories {
    font-size: 12px;
    line-height: 14px;
  }

  .recentlyCompletedWorkCardListItemContent {
    font-size: 10px;
    line-height: 12px;
  }
}

.recently-completed-work-card-scrollbar {
  scrollbar-width: 4px;
  scrollbar-color: #e3ecf6;
}

/* For Webkit (Chrome, Safari, etc.) */
.recently-completed-work-card-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.recently-completed-work-card-scrollbar::-webkit-scrollbar-thumb {
  background-color: #e3ecf6;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.recently-completed-work-card-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
