.contact-hero {
  background: url(../../assets/Components/Contact/contact.webp);
  height: 290px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
}

.card-text {
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  color: #858e96;
}

.icon-container {
  background: rgba(39, 146, 208, 0.1);
}

.icon-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  color: #2792d0;
}

.form-input {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  height: 60px;
  padding-left: 15px;
}
