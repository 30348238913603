.common-product-slider-prevBtn,
.common-product-slider-nextBtn {
  color: #858e96;
  background: #fafafa;
  width: 38px !important;
  height: 38px !important;
  border: 1px solid #d4d4d4;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 16px);
  /* transition: 0.5s ease-out; */
  z-index: 2 !important;
}

.common-product-slider-prevBtn:hover,
.common-product-slider-nextBtn:hover {
  /* background-color: #d13f97; */
  box-shadow: 0 2px 8px 2px rgba(20, 23, 28, 0.15) !important;
  background-color: #fff;
  color: #195ca5;
}

.common-product-slider-prevBtn {
  left: -20px;
}
.common-product-slider-nextBtn {
  right: -20px;
}

.swiper-button-disabled {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease-in-out;
}
