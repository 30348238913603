.heroFooterSectionCardContainer {
  box-shadow: 2px 2px 20px 0px #0000001a;
}

.heroFooterSectionCardTitle {
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.heroFooterSectionCardContent {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .heroFooterSectionCardTitle {
    font-size: 20px;
    line-height: 24px;
  }

  .heroFooterSectionCardContent {
    font-size: 14px;
    line-height: 15px;
  }
}
